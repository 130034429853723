.groups-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  cursor: pointer;
  min-height: 100vh;
}

.groups-container .stations-group {
  flex-basis: 100%;
}

.stations-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 25px;
}

.station-info {
  flex-basis: 400px;
}

.header-dest {
  font-size: 20px;
  margin-left: 8px;
}

.header-dest-dual {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
}

.header-dest-dual > div {
  flex: 50%;
  align-self: flex-end;
  white-space: nowrap;
}

.header-dest-dual > .header-dest {
  margin-left: 0;
}

.header-routes {
  position: relative;
  top: 3px;
}

.bullet.notDisplayed {
  opacity: 0.3;
}

.bullet.inlineBullet {
  margin-top: -3px;
}

.header-routes .bullet {
  padding-right: 2px;
}

.dual-station-stops {
  display: flex;
  flex-wrap: wrap;
}

.dual-station-stops .station-stop {
  flex: 50%;
}

.station-stop {
  display: block;
  line-height: 28px;
  border-bottom: 1px solid rgb(175, 175, 175, 0.5);
  white-space: nowrap;
}

.station-stop:last-child {
  border: 0;
}

.dual-station-stops .station-stop:last-child {
  border-bottom: 1px solid rgb(175, 175, 175, 0.5);
}

.station-stop .bullet {
  position: relative;
  top: -3px;
}

.station-stop .time {
  display: inline-block;
  margin-left: 8px;
  font-size: 20px;
  width: 140px;
}

.station-stop .time-hrmin {
  text-align: right;
  float: right;
  opacity: 0.6;
  letter-spacing: -1px;
}

.station-stop .title {
  white-space: nowrap;
  float: right;
  padding-right: 10px;
}

.bottom-lastupdated {
  position: fixed;
  bottom: 5px;
  right: 5px;
}

body.condensed {
  overflow-x: hidden;
  overflow-y: auto;
}

body.condensed .header-dest {
  max-height: 24px;
  line-height: 24px;
  overflow: hidden;
  align-self: auto;
}

body.condensed h1 {
  margin-top: 0;
  margin-bottom: 8px;
}

.stations-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  cursor: pointer;
}

.stations-list .station {
  display: flex;
  margin: 5px 10px;
  width: 100%;
  flex-wrap: wrap;
  vertical-align: middle;
}

.stations-list .station > a {
  color: black;
  text-decoration: none;
  line-height: 20px;
  padding-left: 5px;
}